<template>
    <div>
        <el-main>
            <el-button type="primary" style="margin-bottom: 10px" size="small"
                @click="$router.push({ path: '/marketing/repairCardAdd' })">+添加消费卡</el-button>
            <!-- 表单 -->
            <el-form class="el-form-search" label-width="140px">
                <el-form-item label="消费卡名称：">
                    <el-input size="small" placeholder="请输入消费卡名称" v-model="searchForm.consumer_card_name"></el-input>
                </el-form-item>
                <el-form-item label="上架状态：">
                    <el-select v-model="searchForm.status" size="small" placeholder="请选择" clearable>
                        <el-option label="全部" value=""></el-option>
                        <el-option label="上架" :value="1"></el-option>
                        <el-option label="下架" :value="-1"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label=" " label-width="25px">
                    <el-button type="primary" size="small" @click="search">搜索</el-button>
                    <el-button type="text" size="small" @click="clearSearch">清空搜索条件</el-button>
                </el-form-item>
            </el-form>
            <!-- 列表 -->
            <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" :key="key">
                <template slot="empty">
                    <No />
                </template>
                <el-table-column prop="consumer_card_name" label="消费卡名称" align="center"></el-table-column>
                <el-table-column prop="consumer_card_picture" label="消费卡图片" align="center">
                    <template v-slot="{ row }">
                        <el-image :src="row.consumer_card_picture" alt="" style="width: 60px;height: 60px;"></el-image>
                    </template>
                </el-table-column>
                <el-table-column prop="consumer_card_amount" label="消费卡金额" align="center"></el-table-column>
                <el-table-column prop="price" label="购买金额" align="center"></el-table-column>
                <el-table-column label="展示/下架" align="center" min-width="100">
                    <template slot-scope="scope">
                        <el-switch v-model="scope.row.status" :active-value="1" :inactive-value="-1"
                            @change="changeIsUp(scope.row)">
                        </el-switch>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center" width="160" fixed="right">
                    <template slot-scope="scope">
                        <el-button type="text" @click="handleDel(scope.row.id)" size="small">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 翻页 -->
            <Paging :total="total_number" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData">
            </Paging>
        </el-main>
    </div>
</template>
  
<script>

import _ from 'lodash';
import Paging from '@/components/paging';

export default {
    components: {
        Paging
    },
    data () {
        return {
            list: [],
            total_number: 0,
            searchForm: {
                page: 1,
                rows: 10,
                consumer_card_name: '',
                status: '',
            }
        };
    },
    created () {
        this.getList();
    },
    methods: {
        search () {
            this.searchForm.page = 1;
            this.getList();
        },
        clearSearch () {
            this.searchForm = {
                page: 1,
                rows: 10,
                consumer_card_name: '',
                status: '',
            };
            this.getList();
        },
        updateData (val, status) {
            if (status == 0) {
                this.searchForm.rows = val;
                this.getList();
            } else {
                this.searchForm.page = val;
                this.getList();
            }
        },
        // 获取列表
        getList () {
            let searchForm = { ...this.searchForm }
            this.$axios.post(this.$api.repair.marketing.ConsumerCardList, searchForm).then(res => {
                if (res.code == 0) {
                    this.list = res.result.list;
                    this.total_number = res.result.total_number;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        changeIsUp (row) {
            this.$axios.post(this.$api.repair.marketing.ConsumerCardSetStatus, {
                id: row.id,
                status: row.status
            }).then(res => {
                if (res.code == 0) {
                    this.$message.success('编辑成功');
                    this.getList();
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        handleDel (id) {
            this.$confirm('确定要删除此数据?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    this.$axios.post(this.$api.repair.marketing.ConsumerCardDel, { id }).then(res => {
                        if (res.code == 0) {
                            this.$message.success('删除成功');
                            this.getList();
                        } else {
                            this.$message.error(res.msg);
                        }
                    });
                })
                .catch(() => { });
        }
    },
};
</script>
  
<style lang="less" scoped>
.el-main {
    background: #fff;
}

.detail {
    width: 600px;

    /deep/img {
        width: 100%;
        height: auto;
    }
}
</style>
  